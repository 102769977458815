import type { ColumnsType } from 'antd/es/table';

interface DataType {
  session: string;
  timestmap: string;
  issuer: string;
  transcription: string;
  compressed: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Sessão',
    dataIndex: 'session',
    key: 'session',
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestmap',
    key: 'timestmap',
  },
  {
    title: 'Emissor',
    dataIndex: 'issuer',
    key: 'issuer',
  },
  {
    title: 'Transcrição',
    key: 'transcription',
    dataIndex: 'transcription',
  },
  {
    title: 'Transcrição Comprimida',
    key: 'compressed',
    dataIndex: 'compressed',
  }
];

export default columns;