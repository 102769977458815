import React, { useState } from 'react';
import './App.css';
import Columns from './TableColumns';

import { StopOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Table } from 'antd';
import { getTranscriptions } from './api';

const App = () => {
  const [data, setData] = useState<any[]>([]);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [fetchInterval, setFetchInterval] = useState<NodeJS.Timer | null>(null);
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    const response = await getTranscriptions(values.session);
    setData(response.data);
    setLastUpdated(new Date());

    const interval = setInterval(async () => {
      const response = await getTranscriptions(values.session);
      setData(response.data);
      setLastUpdated(new Date());
    }, 10000);

    setFetchInterval(interval)
  }

  const stopInterval = () => {
    setLastUpdated(null);
    if (fetchInterval) clearInterval(fetchInterval);
  }

  return (
    <div className="App">
      <body>
        <h1>Transcrições</h1>
        <div className="Form">
          <Form
            form={form}
            layout="inline"
            onFinish={onSubmit}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="session"
              label="Sessão Id"
              rules={[{ required: true }]}
            >
              <Input placeholder="Insira o ID da sessão" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Buscar
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
        <div className="Table">
          <div className="lastUpdate">
            <p>Last update: {lastUpdated?.toLocaleTimeString()} <StopOutlined onClick={stopInterval}/></p>
          </div>
          <Table pagination={false}  style={{width: '100%'}} columns={Columns} dataSource={data} />
        </div>
      </body>
    </div>
  );
}

export default App;
